<script setup
        lang="ts">
import { useScriptTag } from '@vueuse/core';
import { onMounted }    from 'vue';
import { useInteraction } from '@reshepe/hooks';

const props = withDefaults(defineProps<{
    chatId: string | null;
}>(), {});

const { load: chat_load } = useScriptTag(
    '//eu.fw-cdn.com/12781688/764985.js',
    () => {
        if (props.chatId) {
            window.fcWidgetMessengerConfig.restoreId = props.chatId;
        }

        window.fcSettings = {
            onInit() {
                if (!props.chatId) {
                    window.fcWidget.user?.clear(() => {});
                }
            },
        };
    },
    {
        manual: true,
        async:  false,
        defer:  false,
    },
);

onMounted(async () => {
    window.fcWidgetMessengerConfig = {
        config: {
            eagerLoad:      false,
            headerProperty: {
                hideChatButton: false,
            },
        },
    };

    useInteraction(chat_load);
});
</script>

<template>
    <p><!-- --></p>
</template>

<style lang="scss"
       scoped>
// nothing here
</style>
